import { useMutation } from '@apollo/client';
import React from 'react';
import Helmet from 'react-helmet';
import { MAIL_MUTATION } from '../../../../client/__graphql__/mutations/mail';
import { useForm } from '../../../utils/hooks/useForm';
import { useFormTransition } from '../../../utils/hooks/useFormTransition';
import { routeConfig } from '../../../__config__/routes';
import { Button } from '../../ui/button/Button';
import { ButtonGroup } from '../../ui/button/ButtonGroup';
import { Form, FormRow, FormWrapper } from '../../ui/form/Form';
import { FormError } from '../../ui/form/FormError';
import { Input } from '../../ui/form/Input';
import { ButtonLink } from '../../ui/link/ButtonLink';
import { Paragraph } from '../../ui/text/Paragraph';
import { Heading } from '../../ui/text/Heading';
import { Section } from '../../layout/Section';
import { Container } from '../../layout/Container';
import { Thanks } from '../../layout/Thanks';
import { ILeadInput, ILeadResponse } from '../../../__types__/global';
import { useDigtectiveContext } from '../../../__lib__/digtective/context/hooks/useDigtectiveContext';

const Stylist: React.FC = () => {
  const [posted, setPosted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const { submitWithDigger } = useDigtectiveContext();

  const { fadeIn, fadeOut } = useFormTransition({
    posted
  });
  const [mail, { loading: mailLoading, error: mailError }] = useMutation<
    ILeadResponse,
    ILeadInput
  >(MAIL_MUTATION, {
    onCompleted: ({ lead }) => {
      if (lead && lead.mail && lead.mail.success) {
        setPosted(true);
      }
    }
  });

  const { fields, getFields, handleSubmit, handleChange } = useForm({
    fields: [
      {
        type: 'text',
        name: 'name',
        placeholder: 'Ola Nordmann',
        value: '',
        label: 'Navn'
      },
      {
        type: 'text',
        name: 'zip',
        placeholder: '1234',
        value: '',
        label: 'Postnummer *',
        required: true
      },
      {
        type: 'tel',
        name: 'phone',
        placeholder: '00000000',
        value: '',
        label: 'Telefon *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      },
      {
        type: 'email',
        name: 'email',
        placeholder: 'ole@mail.no',
        value: '',
        label: 'E-post *',
        labelAfter: '(Brukes til å kontakte deg)',
        required: true
      }
    ],

    submit: () => {
      const preparedFields: any = getFields(fields);
      setLoading(true);
      submitWithDigger({ zip: preparedFields.zip }, ({ diggerId }) => {
        mail({
          variables: {
            input: {
              ...preparedFields,
              diggerId,
              referer: `${location.pathname}${location.search}`,
              leadType: 'EPOST',
              mailType: 99
            }
          }
        });
      });
    }
  });

  return (
    <>
      <Helmet>
        <script src="https://embed.vev.page/v1/VjeUhIp3xh/p5et88GBCcy" />
      </Helmet>

      <Container>
        <FormWrapper style={fadeOut}>
          <Section className="form-section">
            <Heading tag="h2" center={true}>
              Boligstyling eller gjøre det selv?
            </Heading>

            <Paragraph
              className="form-text"
              center={true}
              style={{ margin: '0 0 10px', textAlign: 'left' }}
            >
              Vurderer du å selge og lurer på hvordan du kan forberede salget
              best mulig? Våre meglere hjelper deg gjerne med planleggingen.
              Fyll ut skjema under og en av våre lokalkjente eiendomsmeglere vil
              ta kontakt.
            </Paragraph>

            <Form onSubmit={handleSubmit} noValidate>
              {mailError &&
                mailError.graphQLErrors.map((error, index) => (
                  <FormError key={`error_${index}`} message={error.message} />
                ))}
              {fields.map((item, index) => {
                return (
                  <FormRow key={`field_${index}`}>
                    <Input
                      type={item?.type}
                      name={item?.name}
                      label={item?.label}
                      labelAfter={item?.labelAfter}
                      placeholder={item?.placeholder}
                      value={item?.value}
                      onChange={handleChange}
                      error={item?.error}
                    />
                  </FormRow>
                );
              })}

              <Paragraph className="form-text" style={{ margin: '0 0 10px' }}>
                Jeg anmoder PrivatMegleren om å kontakte meg på e-post og /eller
                telefon i form av sms eller oppringing.
              </Paragraph>

              <ButtonGroup>
                <Button
                  type="primary"
                  colorTheme="gold"
                  disabled={mailLoading}
                  loading={mailLoading}
                >
                  Kontakt meg
                </Button>
                <ButtonLink
                  as="a"
                  href={routeConfig?.personvern.url}
                  className="privacy-link-mobile"
                >
                  Personvernpolicy
                </ButtonLink>
              </ButtonGroup>
            </Form>
          </Section>
        </FormWrapper>
        <Thanks style={fadeIn}>
          <Section className="form-section">
            <Heading tag="h1" center={true}>
              Tusen takk
            </Heading>
            <Paragraph center={true}>
              En eiendomsmegler vil ta kontakt med deg så snart som mulig.
            </Paragraph>
          </Section>
        </Thanks>
      </Container>
    </>
  );
};

export default Stylist;
